import { BOOLEAN, RATING, TEXT, ASCENDING_RATING, DESCENDING_RATING } from "./questions";

export const questionTypes = [
    { value: BOOLEAN, label: 'Yes/No' },
    { value: RATING, label: 'Rating (0 to 10)' },
    { value: TEXT, label: 'Open Ended' },
    { value: ASCENDING_RATING, label: 'BAM Questions: Higher scores = Higher protection from relapse'},
    { value: DESCENDING_RATING, label: 'BAM Questions: Higher scores = Higher risk for relapse'}
  ];
  
  