import React from 'react';
import Dropdown from '../Dropdown';
import { useModal } from '../../Hooks/useModal';
import { ModalContent } from '../../Constants/modalContent.enum';
import { PRE } from '../../Constants/questions';
import { useQuestions } from '../../Hooks/useQuestions';
import { capitalizeFirstLetter } from '../../helper';

const Question = ({ question }) => {
  const {handleDelete, handleCopyQuestion, handleUpdateQuestion} = useQuestions()
  const {showModalWith} = useModal()

  const editFn = () => {
      showModalWith({
        name: ModalContent.EditQuestionForm,
        props: {
          question,
          handleUpdateQuestion
        }
      })
  }
    
  const deleteFn = () => {
    showModalWith({
      name: ModalContent.Confirmation,
      props: {
        delete : () => handleDelete(question?.id)
      }
    })
  }

  const options = [
      {label:"Edit" , fn: editFn},
      {label:"Delete" , fn: deleteFn},
      {label: question?.questionTime !== PRE ? "Add to pre" : "Add to post", fn: () => handleCopyQuestion(question?.id) }
  ]
    
  return (
    <div className="flex justify-between rounded-md items-center shadow-md p-3">
      <p className="3xl:text-lg mb-1 px-1">{capitalizeFirstLetter(question?.question)}</p>
      <Dropdown 
        options={options}
        style='horizontal'
      />
    </div>
  );
};

export default Question;
