import React from 'react'
import { useAppSelector } from '../../Store/store'
import { GetUser } from '../../Features/Auth/userSlice'
import Heading from '../../Components/Heading'

const ThankYou = () => {
  const {data:user} = useAppSelector(GetUser)

  return (
    <div className='flex flex-col gap-4 justify-center items-center'>
        <Heading level={2} className='my-5 font-primary'>
            Thank You, {user?.displayName}!
        </Heading>

        <a href="/" className=" bg-primary font-semibold text-white w-[10rem] py-3 rounded-lg text-sm my-3 text-center">
          Go to Homepage
        </a>
    </div>
  )
}

export default ThankYou