import { Outlet } from "react-router-dom"
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import {Suspense} from "react"
import Loader from "../Components/Loader"

export default function Layout() {
    return (
            <div className="app-wrapper">
                <Header />
                <main className="relative flex flex-col items-center justify-center flex-wrap mx-auto w-full max-w-screen-2xl p-6 lg:px-8 my-10 bg-white rounded-md flex-1">
                        <Suspense fallback={<Loader />}>
                            <Outlet />
                        </Suspense>
                </main>
                <Footer />
            </div>
    )
}
