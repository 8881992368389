import React, { useState } from 'react';
import { Button } from '../Button';
import { useMeet } from '../../Hooks/useMeet';
import TimePicker from 'react-time-picker';
import DatePicker from 'react-date-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SelectField from '../SelectField';
import { useAppSelector } from '../../Store/store';
import { useNavigate } from 'react-router-dom';
import { selectSpaceTemplates } from '../../Features/MeetingsList/meetingTemplateSlice';
import { meetingFrequency } from '../../Constants/meetingFrequency';

const AddNewSessionForm = () => {
  const [isRepeatChecked, setIsRepeatChecked] = useState(false);

  const navigate = useNavigate();
  const {handleCreate , addingMeet} = useMeet()
  const { data: templatesData } = useAppSelector(selectSpaceTemplates) as any;

  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 2);
  nextMonth.setDate(1);
  nextMonth.setHours(0, 0, 0, 0);

  const allSpaceTemplates = templatesData && templatesData?.map(template => {
    return {
      value: template?._id,
      label: template?.name,
      image: template?.images.length > 0 ? template?.images[0] : ""
    };
  });

  const spaceTemplates = [...allSpaceTemplates.filter(item => item.value==="654d2dff101b668982d55441"), ...allSpaceTemplates.filter(item=> item.value !== "654d2dff101b668982d55441")];

  const validationSchema = Yup.object({
    meetingName: Yup.string().required('Meeting Name is required'),
    meetingDescription: Yup.string(),
    meetingTime: Yup.string().required('Meeting Time is required'),
    meetingDate: Yup.date().required('Meeting Date is required'),
  });

  return (
    <Formik
      initialValues={{
        meetingName: "",
        meetingDescription: "",
        meetingTime: "10:00",
        meetingDate: new Date(),
        template: spaceTemplates[0],
        repeat: false,
        frequency: meetingFrequency[0],
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        let cronExpression = isRepeatChecked ? values?.frequency?.value : ""
        if (isRepeatChecked && values?.frequency?.value) {
          const minute = parseInt(values.meetingTime.split(":")[1], 10).toString();
          const hour = parseInt(values.meetingTime.split(":")[0], 10).toString();
          cronExpression = cronExpression
          .replace('0 0', `${minute} ${hour}`);
        } 
        const res:any = await handleCreate({...values,cronExpression});
        navigate(`/set-up-questions?id=${res?.id}`);
      }}
      validateOnBlur={false}
    >
      {formik => (
        <div className="mx-auto my-5">
          <form className='flex flex-col justify-center' onSubmit={formik.handleSubmit}>
            <div className='flex flex-col md:flex-row flex-wrap gap-4 w-full mb-6'>
              <div className="flex-1">
                <label htmlFor="meetingName" className="block text-sm font-medium text-gray-600">
                  Meeting Name
                </label>
                <input
                  type="text"
                  id="meetingName"
                  name="meetingName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.meetingName}
                  className="mt-1 p-2 h-[3.2rem] w-full border border-gray-300 rounded-md bg-white"
                />
                {formik.touched.meetingName && (
                  <div className="text-red-500">{formik.errors.meetingName}</div>
                )}
              </div>
              <div className='flex-1'>
                <label className='block text-sm font-medium text-gray-600 mb-1'> Meeting Room </label>
                <div className='w-full border border-gray-300 rounded-md bg-white'>
                  <SelectField
                    label="space"
                    name="template"
                    options={spaceTemplates}
                    bg="bg-white cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="mb-6">
              <label htmlFor="meetingDescription" className="block text-sm font-medium text-gray-600">
                Meeting Description
              </label>
              <input
                type="text"
                id="meetingDescription"
                name="meetingDescription"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.meetingDescription}
                className="mt-1 p-2 h-[3.2rem] w-full border border-gray-300 rounded-md bg-white"
              />
              {formik.touched.meetingDescription && (
                <div className="text-red-500">{formik.errors.meetingDescription}</div>
              )}
            </div>
            <div className='flex flex-col md:flex-row flex-wrap gap-4 w-full mb-6'>
              <div className="flex-1">
                <label htmlFor="meetingDate" className="block text-sm font-medium text-gray-600">
                  Meeting Date
                </label>
                <DatePicker
                  onChange={(value) => formik.setFieldValue('meetingDate', value)}
                  value={formik.values.meetingDate}
                  calendarIcon={false}
                  minDate={new Date()}
                  maxDate={nextMonth}
                  className="mt-1 p-2 h-[3.2rem] w-full border border-gray-300 rounded-md bg-white"
                />
                {formik.touched.meetingDate && (
                  <div className="text-red-500">{formik.errors.meetingDate as any}</div>
                )}
              </div>
              <div className="flex-1">
                <label htmlFor="meetingTime" className="block text-sm font-medium text-gray-600">
                  Meeting Time
                </label>
                <TimePicker
                  isOpen={false}
                  format={"hh:mm a"}
                  maxDetail={"minute"}
                  onChange={(value) => formik.setFieldValue('meetingTime', value)}
                  value={formik.values.meetingTime}
                  clockIcon={false}
                  className="mt-1 p-2 h-[3.2rem] w-full border border-gray-300 rounded-md bg-white"
                />
                {formik.touched.meetingTime && (
                  <div className="text-red-500">{formik.errors.meetingTime}</div>
                )}
              </div>
            </div>
            <div className="mb-6">
              <div className='flex gap-2 items-center mb-4'>
                <input type="checkbox" id="repeat" name="repeat" className='bg-primary text-white w-4 h-4 rounded-md border-primary cursor-pointer' checked={formik.values.repeat}
                  onChange={(e) => {
                    formik.setFieldValue('repeat', e.target.checked);
                    setIsRepeatChecked(e.target.checked);
                  }} />
                <label htmlFor='repeat' className="block text-sm font-medium"> Repeat this meeting </label>
              </div>
              {isRepeatChecked && <div className="w-full border border-gray-300 rounded-md bg-white">
              <SelectField
                  label="Frequency"
                  name="frequency"
                  options={meetingFrequency}
                  bg="bg-white cursor-pointer"
                />
              </div>}
            </div>
            <Button disabled={Object.keys(formik?.errors).length > 0 || addingMeet} type="submit" className="bg-primary text-white px-8 py-4 my-3">
              Continue
            </Button>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default AddNewSessionForm;
