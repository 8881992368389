import { FC, ReactNode } from 'react'
import NotificationWrapper from '.'
import Modal from '../Modal'

type Props = {
  children: ReactNode
}
const NotificationContainer: FC<Props> = ({ children }) => {
  return (
    <>
      {children}
      <NotificationWrapper />
      <Modal />
    </>
  )
}

export default NotificationContainer
