import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from 'firebase/auth'

export const apiSliceBase = createApi({
  reducerPath: 'api-gt',
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      // TODO this needs to be updated to a selector on the ID token because the below is undefined on app load
      const token = await getAuth().currentUser?.getIdToken()
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      /**
       * File uploads must be sent as FormData in the body with no content-type set
       * This allows for Multipart Boundary to be auto-handled and the file to be correctly
       *  received by mirror-server
       *
       * TODO
       *  Make one endpoint to check for all file uploads
       */
      if (!endpoint.toLowerCase().includes('upload')) {
        headers.set('Content-Type', 'application/json')
      }

      return headers
    }
  }),
  tagTypes: [
    'Auth',
    'User',
    'VidSdk',
    'ProviderMeetings',
    'ClientMeetings',
    'SpaceTemplates',
  ],
  endpoints: (builder) => ({})
})
