import React, { useEffect, useState } from 'react';
import generatePDF from 'react-to-pdf';
import { useNavigate } from 'react-router-dom';
import Patient from '../../Components/Patient';
import { useGoodThoughtsControllerGetPatientsForGtSessionBySessionIdQuery, useGoodThoughtsControllerGetQuestionsForGtSessionBySessionIdQuery } from '../../Features/API/apiSlice.generated';
import { useNotification } from '../../Hooks/useNotification';
import { NotificationType } from '../../Constants/notificationType.enum';

const SummaryPage = () => {
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const date = currentDate.toLocaleDateString();
  const time = currentDate.toLocaleTimeString();

  const {notify} = useNotification()

  const urlParams = new URLSearchParams(window.location.search);
  const participants = urlParams.get('participants');
  const sessionId:any = urlParams.get('sessionId');

  const patientIds = participants?.split(',');

  const navigate = useNavigate();

  useEffect(() => {
    notify("Please refresh the page if any patient information is missing." , NotificationType.WARNING);
 }, [])

  const { data: patients, isLoading: patientsLoading, isError: patientsError } = useGoodThoughtsControllerGetPatientsForGtSessionBySessionIdQuery({
    sessionId: sessionId
  } , {
     skip: !patientIds
  });

  const {data:sessionQuestions, isLoading: questionsLoading, isError: questionsErrors} = useGoodThoughtsControllerGetQuestionsForGtSessionBySessionIdQuery({
    sessionId: sessionId
  } , {
     skip: !patientIds
  })

  const handleHomeRoute = () => {
    navigate("/", { replace: true });
  }

  const groupOptions = {
    filename: `Group_summary_${date}.pdf`,
    page: {
      margin: 10,
      orientation: "landscape"
    }
  };

  const downloadGroupPdf = async () => {
    setLoading(true);
    setTimeout(async () => {
      const getTargetElement = () => document.getElementById(`print-group-container`);
      await generatePDF(getTargetElement, groupOptions as any);
      setLoading(false);
    }, 500);
  };

  if (patientsLoading) {
    return <div>Loading...</div>;
  }

  if (patientsError) {
    return <div>Error loading patients</div>;
  }

  const filteredPatients = !patientsLoading && patients && patients?.filter((patient:any) => patientIds && patientIds.includes(patient?.user));

  return (
    <>
      <div id="print-group-container" className="mx-auto w-full px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">Summary</h1>
        <p className="my-1 font-semibold"> Date: {date} </p>
        <p className="mb-4 font-semibold"> Time: {time} </p>
        {filteredPatients && filteredPatients.map((patient:any) => (
          patient.answers.length > 0 && <Patient key={patient?.id} id={patient?.user} patientAnswers={patient?.answers} sessionQuestions={sessionQuestions} generatePDF={generatePDF} loading={loading} />
        ))}
        <div className='flex flex-col w-full my-4'>
          <label className='text-lg font-semibold mb-4' title='notes'> Group Notes: </label>
          <textarea className='border border-gray-200 p-2 rounded-md' name="notes" id="notes" cols={30} rows={8}>
          </textarea>
        </div>
      </div>

      <div className='flex justify-end items-center gap-3'>
        <button
          onClick={downloadGroupPdf}
          disabled={loading}
          className={`h-16 w-[16rem] text-sm bg-primary text-white font-semibold rounded-lg py-3 px-4 my-3 ${loading && "opacity-80"}`}
        >
          {loading ? "Downloading..." : "Download Group Summary"}
        </button>
        <button onClick={handleHomeRoute} className="h-16 w-[16rem] text-sm bg-primary font-semibold text-white py-3 px-4 rounded-lg my-3 text-center">
          Go to Homepage
        </button>
      </div>
    </>
  );
};

export default SummaryPage;
