import React from 'react'
import { useParams } from 'react-router-dom'

const ErrorsPage = () => {
  const params = useParams()
  const { error } = params

  const formatErrorMessage = errorMessage => {
    return errorMessage.replace(
      /((http|https):\/\/[^\s]+)/g,
      '<a href="$1">$1</a>'
    );
  };

  const getErrorMessage = errorCode => {
    switch (errorCode) {
      case 'ACCESS_DENIED':
        return "You may need to request access to this space."
      case 'ACCOUNT_IN_USE':
        return "Your account is currently in use, please log out of the other computer."
      case 'ACCOUNT_JOINED':
        return "Someone with your account joined this server, please ensure nobody is using your account."
      case 'VERSION_MISMATCH':
        return formatErrorMessage("You are running an older version of the game, please go to itch.io and check for updates. You can find more information here: https://docs.themirror.space/docs/get-started")
      case 'SERVER_ERROR':
        return formatErrorMessage("The server reported an unforeseen error, please report this issue on our discord at: https://discord.gg/pf96gDtpXD")
      case 'UNKNOWN_ERROR':
        return formatErrorMessage("There was an unknown error, please report this issue on our discord at: https://discord.gg/pf96gDtpXD")
      case 'CLIENT_TIMEOUT':
        return formatErrorMessage("You experienced a network issue with your internet or with the server, you can try rejoining or you can report an issue here: https://discord.gg/pf96gDtpXD")
      default:
        return "Unknown error occurred."
    }
  }

  return (
    <div className='max-w-[60%] mx-auto'>
      <p className='text-lg font-medium text-center leading-loose' dangerouslySetInnerHTML={{ __html: getErrorMessage(error) }}></p>
    </div>
  )
}

export default ErrorsPage
