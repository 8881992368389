import React from 'react'
import AddNewSessionForm from '../../Components/AddNewSessionModal'
import Heading from '../../Components/Heading'

export const CreateSession = () => {
  return (
    <div className="w-full md:w-[40rem] mx-auto px-5">
        <Heading level={3} className="mb-6 text-center capitalize">
            Schedule a new session
        </Heading>
        <AddNewSessionForm />
    </div>
  )
}
