import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CloseModalIcon from './CloseModalIcon'
import { useAppSelector } from '../../Store/store'
import { selectModal } from '../../Features/Modal/modalSlice'
import { MODAL_MAP } from '../../Features/Modal/modalMap'
import { useModal } from '../../Hooks/useModal'

const Modal = () => {
  const { show, component } = useAppSelector(selectModal)
  const { dismissModal } = useModal()

  /** Dynamically created from ModalSlice component using MODAL_MAP. */
  const ModalContent = (): JSX.Element => {
    if (!component?.name) {
      return <div>Something went wrong!</div>
    }

    const ModalComponent = MODAL_MAP[component.name]
    return <ModalComponent {...component.props} />
  }

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => {
            dismissModal()
          }}
        >
          <div className="flex items-center justify-center min-h-screen text-center p-2">
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-lightBg bg-opacity-75" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-in duration-100"
              enterFrom="opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95"
              enterTo="opacity-100 translate-y-0 mobile:scale-100"
              leave="ease-out duration-100"
              leaveFrom="opacity-100 translate-y-0 mobile:scale-100"
              leaveTo="opacity-0 translate-y-4 mobile:translate-y-0 mobile:scale-95"
            >
              <div className="text-left transition-all transform backdrop-filter backdrop-blur-lg rounded-lg shadow-xl px-5 lg:px-[6rem] py-[4rem] mobile:p-8 mobile:py-14 sm:max-w-[75%] lg:max-w-[40%] 3xl:max-w-[30%] flex-1">
                <CloseModalIcon closeModal= { () => {
                  dismissModal()
                }
                }/>
                <ModalContent />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Modal
