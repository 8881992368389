import React from 'react';
import { Formik, Form, Field } from 'formik';
import Heading from '../Heading';
import SelectField from '../SelectField';
import { questionTypes } from '../../Constants/questionTypes';
import { Button } from '../Button';
import { useModal } from '../../Hooks/useModal';

const AddQuestionForm = ({questionTime, handleAddQuestion}) => {
  const {dismissModal} = useModal()

  const initialValues = {
    questionText: "",
    questionType: questionTypes[0],
    questionTime: questionTime
  };

  const handleSubmit = async (values) => {
    const question = {
      questionText: values?.questionText,
      questionType: values?.questionType?.value,
      questionTime: values?.questionTime
    }
    await handleAddQuestion(question)
    dismissModal()
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({values}) => (
        <Form className='flex flex-col gap-5'>
            <Heading level={3}>Add your question</Heading>
            <div>
              <Field component="textarea" rows={2} name="questionText" className="flex-1 mt-1 p-3 w-full border border-gray-300 focus:outline-none rounded-xl bg-white" />
            </div>
            <div className='border border-gray-300 rounded-xl'>
              <SelectField
                label="Type of question"
                name="questionType"
                options={questionTypes}
                bg="bg-white p-3 cursor-pointer w-full flex-1"
              />
            </div>
            <div className='flex gap-3 justify-center'>
              <Button onClick={() => handleSubmit(values)} className='my-2 bg-primary text-white text-sm'>
                Save
              </Button>
              <Button onClick={dismissModal} className='my-2 bg-secondary text-white text-sm'>
                Cancel
              </Button>
            </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddQuestionForm;
