import { createSlice } from '@reduxjs/toolkit'
import { AppState } from '../../Store/store'
import { ModalContent } from '../../Constants/modalContent.enum'

interface ModalSlice {
  show: boolean
  component: {
    name: ModalContent | null
    props: any
  }
}

const initialState: ModalSlice = {
  show: false,
  component: {
    name: null,
    props: null
  }
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action): void {
      state.show = true
      state.component = action.payload.component
    },
    hideModal(state): void {
      state.show = false
    }
  },
})

export const { showModal, hideModal } = modalSlice.actions

export const selectModal = (state: AppState) => state.modal
