export const meetingFrequency = [
    {
      label: 'Daily',
      value: '0 0 * * *', // Run every day at midnight
    },
    {
      label: 'Monday of every week',
      value: '0 0 * * 1', // Run every Monday at midnight
    },
    {
      label: 'Monday and Friday of every week',
      value: '0 0 * * 1,5', // Run every Monday and Friday at midnight
    },
    {
      label: 'First day of every month',
      value: '0 0 1 * *', // Run on the 1st day of every month at midnight
    },
    {
      label: 'First Monday of every month',
      value: '0 0 * * 1#1', // Run on the 1st Monday of every month at midnight
    },
    {
      label: 'Every weekday (Monday to Friday)',
      value: '0 0 * * 1-5', // Run every weekday (Monday to Friday) at midnight
    },
  ];