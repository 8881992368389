import React from 'react'
import Heading from '../Heading'
import Paragraph from '../Paragraph'
import { useModal } from '../../Hooks/useModal'
import { Button } from '../Button'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const Confirmation = (props) => {
  const {dismissModal} = useModal()
  const handleDelete = async () => {
    await props.delete()
    dismissModal()
  }
 return (
    <div className='flex flex-col items-center'>
        <ExclamationTriangleIcon className='text-danger mb-4 text-center w-12 h-12' />
        <Heading level={3}> Are you sure ? </Heading>
        <Paragraph className='text-center my-3'>This action cannot be undone. All values associated with this will be lost.</Paragraph>
        <div className='flex gap-3 mt-2'>
          <Button onClick={handleDelete}  className='shadow-none bg-danger text-white border-none w-[8.5rem] '> Yes, delete it </Button>
          <Button onClick={dismissModal} className='shadow-none bg-secondary text-white border-none w-[8rem]'> Cancel </Button>
        </div>
    </div>
  )
}

export default Confirmation