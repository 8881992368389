import { apiSlice } from '../API/apiSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiSlice.enhanceEndpoints<'ClientMeetings'>({
  endpoints: {
    goodThoughtsControllerFindSessions: {
      providesTags: (result, error, arg) => [{ type: 'ClientMeetings' }]
    }
  }
})

export const getClientMeetings =
  apiSlice.endpoints.goodThoughtsControllerFindSessions.select({id:""})



