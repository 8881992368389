import { apiSlice } from '../API/apiSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiSlice.enhanceEndpoints<'ProviderMeetings'>({
  endpoints: {
    goodThoughtsControllerFindSessionsWhereUserCreator: {
      providesTags: (result, error, arg) => [{ type: 'ProviderMeetings' }]
    },
    goodThoughtsControllerCreateSessionVideoSdk: {
      invalidatesTags: (result, error, arg) => [{ type: 'ProviderMeetings'}]
    },
    goodThoughtsControllerDeleteSessionVideoSdk: {
      invalidatesTags: (result, error, arg) => [{ type: 'ProviderMeetings'}]
    },
    goodThoughtsControllerUpdateSessionVideoSdk: {
      invalidatesTags: (result, error, arg) => [{ type: 'ProviderMeetings'}]
    }
  }
})

export const getProviderMeetings = 
apiSlice.endpoints.goodThoughtsControllerFindSessionsWhereUserCreator.select()


