import React, { lazy } from "react";
import PathConstants from "./pathConstants";
import { ProtectedRoute } from "./ProtectedRoute";
import { USER_ROLES } from "../Constants/roles";
import { Navigate } from "react-router-dom";
import { AuthGuard } from "../Components/AuthGuard";
import { useAppSelector } from "../Store/store";
import { GetUser } from "../Features/Auth/userSlice";
import Instructions from "../Pages/RPMInstructions";
import SettingsPage from "../Pages/SettingsPage";
import ThankYou from "../Pages/ThankYou";
import ErrorsPage from "../Pages/ErrorsPage";
import SummaryPage from "../Pages/Summary";
import { SetUpQuestions } from "../Pages/SetUpQuestions";
import { CreateSession } from "../Pages/CreateSession";
import { EditSession } from "../Pages/EditSession";
import AddPatientToSession from "../Pages/AddPatients";

const HomeClient = lazy(() => import("../Pages/HomeClient"));
const HomeProvider = lazy(() => import("../Pages/HomeProvider"));
const SignInForm = lazy(() => import("../Pages/SignIn"));
const SignUpForm = lazy(() => import("../Pages/Register"));
const ForgotPasswordForm = lazy(() => import("../Pages/ForgotPassword"));
const PreSessionQuestions = lazy(() => import("../Pages/PreSessionQuestions"));
const PostSessionQuestions = lazy(
  () => import("../Pages/PostSessionQuestions")
);
const ClientMeetRoom = lazy(() => import("../Pages/MeetRoom"));
const Room = lazy(() => import("../Pages/Room/Room"));
const ModifyAvatar = lazy(() => import("../Pages/ModifyAvatar"));
const Profile = lazy(() => import("../Pages/Profile"));
const NotFound = lazy(() => import("../Pages/NotFound"));

interface RouteItem {
  path: string;
  element: JSX.Element;
}

interface RoleProps {
  allowedRole: string[];
  children: JSX.Element;
  redirectPath: string;
}

const HomeRoute: React.FC = () => {
  const { data: user } = useAppSelector(GetUser);
  const userRole = user?.gtUserData?.gtUserType;
  if (userRole === USER_ROLES.CLIENT || !userRole) {
    return <HomeClient />;
  } else if (userRole === USER_ROLES.PROVIDER) {
    return <HomeProvider />;
  }
  return null;
};

const CheckRole: React.FC<RoleProps> = ({
  allowedRole,
  children,
  redirectPath,
}) => {
  const { data: user } = useAppSelector(GetUser);
  if (user) {
    const userRole = user?.gtUserData?.gtUserType;
    if (
      allowedRole &&
      allowedRole.length > 0 &&
      allowedRole.includes(userRole)
    ) {
      return children;
    } else {
      return <Navigate to={redirectPath} />;
    }
  }
  return null;
};

const routes: RouteItem[] = [
  {
    path: PathConstants.HOME,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <HomeRoute />
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.LOGIN,
    element: (
      <AuthGuard>
        <SignInForm />
      </AuthGuard>
    ),
  },
  {
    path: PathConstants.REGISTER,
    element: (
      <AuthGuard>
        <SignUpForm />
      </AuthGuard>
    ),
  },
  {
    path: PathConstants.FORGOT,
    element: <ForgotPasswordForm />,
  },
  {
    path: PathConstants.MODIFY,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <ModifyAvatar />
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.QUESTIONS,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <CheckRole
          redirectPath={PathConstants.HOME}
          allowedRole={[USER_ROLES.CLIENT]}
        >
          <PreSessionQuestions />
        </CheckRole>
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.POSTQUESTIONS,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <CheckRole
          redirectPath={PathConstants.HOME}
          allowedRole={[USER_ROLES.CLIENT]}
        >
          <PostSessionQuestions />
        </CheckRole>
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.SESSION,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <CheckRole
          redirectPath={PathConstants.HOME}
          allowedRole={[USER_ROLES.CLIENT]}
        >
          <ClientMeetRoom />
        </CheckRole>
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.ROOM,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <Room />
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.INSTRUCTIONS,
    element: <Instructions />,
  },
  {
    path: PathConstants.PROFILE,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.SETTING,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <SettingsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.THANKYOU,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <ThankYou />
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.CREATESESSION,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <CheckRole
          redirectPath={PathConstants.HOME}
          allowedRole={[USER_ROLES.PROVIDER]}
        >
          <CreateSession />
        </CheckRole>
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.EDITSESSION,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <CheckRole
          redirectPath={PathConstants.HOME}
          allowedRole={[USER_ROLES.PROVIDER]}
        >
          <EditSession />
        </CheckRole>
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.ADDPATIENTTOSESSION,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <CheckRole
          redirectPath={PathConstants.HOME}
          allowedRole={[USER_ROLES.PROVIDER]}
        >
          <AddPatientToSession />
        </CheckRole>
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.SETUPQUESTION,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <CheckRole
          redirectPath={PathConstants.HOME}
          allowedRole={[USER_ROLES.PROVIDER]}
        >
          <SetUpQuestions />
        </CheckRole>
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.SUMMARY,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <CheckRole
          redirectPath={PathConstants.HOME}
          allowedRole={[USER_ROLES.PROVIDER]}
        >
          <SummaryPage />
        </CheckRole>
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.ERROR,
    element: (
      <ProtectedRoute redirectPath={PathConstants.LOGIN}>
        <ErrorsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: PathConstants.NOTFOUND,
    element: <NotFound />,
  },
];

export default routes;
