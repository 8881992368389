import React from 'react';

type ParagraphProps = {
    children: string;
    className?: string;
  };

const Paragraph:React.FC<ParagraphProps>  = ({ className, children }) => {
  return (
    <p
      className={`${className}`}
    >
      {children}
    </p>
  );
};

export default Paragraph;
