import { useAppDispatch, useAppSelector } from "../Store/store";
import { CreateGtSessionVideoSdkDto, CreatePatientByUserEmailDto, UpdateGtSessionVideoSdkDto, apiSlice, useGoodThoughtsControllerAddPatientByUserEmailByProviderMutation, useGoodThoughtsControllerAddPatientToSessionOnlyByUserIdMutation, useGoodThoughtsControllerCreateSessionVideoSdkMutation, useGoodThoughtsControllerDeletePatientByProviderMutation, useGoodThoughtsControllerDeleteSessionVideoSdkMutation, useGoodThoughtsControllerGetPatientsForGtSessionBySessionIdQuery, useGoodThoughtsControllerUpdatePatientByProviderMutation, useGoodThoughtsControllerUpdateSessionVideoSdkMutation} from "../Features/API/apiSlice.generated"
import { NotificationType, useNotification } from "../Features/Notification"
import { getVidSdkAuth } from "../Features/VideoSdkAuth/videoSdkAuthSlice";
import { useModal } from "./useModal";
import { GetUser } from "../Features/Auth/userSlice";
import { USER_ROLES } from "../Constants/roles";

export const useMeet = () => {
    const {data:user} = useAppSelector(GetUser);
    const dispatch = useAppDispatch()
    const API_BASE_URL = "https://api.videosdk.live";
    const {notify} = useNotification()
    const {dismissModal} = useModal()
    const {data:tokenObj} = useAppSelector(getVidSdkAuth)
    const token = (tokenObj as any)?.token || ""
    const url = new URL(window.location.href);
    const sessionId = url.searchParams.get('id') || "";
    const userRole = user?.gtUserData?.gtUserType

    const [createMeet , {isLoading : addingMeet}] = useGoodThoughtsControllerCreateSessionVideoSdkMutation()
    const [updateMeet] = useGoodThoughtsControllerUpdateSessionVideoSdkMutation()
    const [deleteMeet] = useGoodThoughtsControllerDeleteSessionVideoSdkMutation()
    const [addPatient , {isLoading:addingPatient}] = useGoodThoughtsControllerAddPatientToSessionOnlyByUserIdMutation()
    const [deletePatient , {isLoading : deletingPatient}] = useGoodThoughtsControllerDeletePatientByProviderMutation()
    const [updatePatient, {isLoading : updatingPatient}] = useGoodThoughtsControllerUpdatePatientByProviderMutation()

    // const {data: patients, isLoading : patientsLoading , isError : patientsError , refetch : refetchPatient} = useGoodThoughtsControllerGetPatientsForGtSessionBySessionIdQuery({
    //   sessionId: sessionId
    // }, {
    //   skip: !sessionId
    // })

    const createRoom = async (authToken:string) => {
        const url = `${API_BASE_URL}/v2/rooms`;
        const options = {
          method: "POST",
          headers: { Authorization: authToken, "Content-Type": "application/json" },
        };
      
        const room = await fetch(url, options)
          .then((response) => response.json())
          .catch((error) => console.error("error", error));
      
        return room
    };

    const validateMeeting = async ({ roomId, token }) => {
      const url = `${API_BASE_URL}/v2/rooms/validate/${roomId}`;
    
      const options = {
        method: "GET",
        headers: { Authorization: token, "Content-Type": "application/json" },
      };
    
      const result = await fetch(url, options)
        .then((response) =>  {
          if(response.ok && response.status == 200) {
            return response.json()
          } else {
             notify("Third-party VideoSDK error", NotificationType.ERROR)
          }
        }) 
        .catch((error) => console.error("error", error));
        return result ? result?.roomId === roomId : false
    };

    const handleCreate = async (values) => {
        const room = await createRoom(token)
        if(room?.roomId !== "") {
            const formatDate = values?.meetingDate.toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            const time = values?.meetingTime
            const localDateTimeString = `${formatDate} ${time}`
            const localDateTimeObj = new Date(Date.parse(localDateTimeString))
            const createGtSessionVideoSdkDto:CreateGtSessionVideoSdkDto = {
                name : values?.meetingName,
                description : values?.meetingDescription,
                startAt: localDateTimeObj.toISOString(),
                room: room,
                sessionIsActive: false,
                spaceFromTemplate : values?.template?.value
            }
            if(values?.cronExpression !== "") {
              createGtSessionVideoSdkDto.cronExpression = values?.cronExpression
            }
            try {
              const res = await createMeet({createGtSessionVideoSdkDto}).unwrap()
              dismissModal()
              dispatch(apiSlice.util.invalidateTags(['ClientMeetings']))
              return res;
            } catch (error) {
              notify("Something went wrong!", NotificationType.ERROR)
            }
        }
    }

    const handleUpdate = async (meetId, values, room) => {
       const roomId = room?.roomId
       const isRoomValid = await validateMeeting({roomId, token})
       if(isRoomValid) {
          const formatDate = values?.meetingDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          const time = values?.meetingTime
          const localDateTimeString = `${formatDate} ${time}`
          const localDateTimeObj = new Date(Date.parse(localDateTimeString))
          const updateGtSessionVideoSdkDto:UpdateGtSessionVideoSdkDto = {
            name : values?.meetingName,
            description : values?.meetingDescription,
            startAt: localDateTimeObj.toISOString(),
            room: room
          }
          try {
            await updateMeet({id:meetId, updateGtSessionVideoSdkDto})
            dismissModal()
            notify("Meeting updated successfully", NotificationType.SUCCESS)
            dispatch(apiSlice.util.invalidateTags(['ClientMeetings']))
          } catch (error) {
            notify("Something went wrong!", NotificationType.ERROR)
          }
       } else {
         notify("This session is expired!", NotificationType.ERROR)
       }
    }

    const handleDelete = async (id:string) => {
      try {
        await deleteMeet({id}).unwrap()
        dismissModal()
        notify("Meeting deleted successfully", NotificationType.SUCCESS)
        dispatch(apiSlice.util.invalidateTags(['ClientMeetings']))
      } catch (error) {
        console.log(error)
        notify("Something went wrong!", NotificationType.ERROR)
      }
    }

    const handleAddPatient = async(sessionId:string) => {
         try {
            await addPatient({sessionId}).unwrap();
         } catch (error:any) {
            if (error?.data) {
              notify(error.data.message, NotificationType.ERROR);
            } else {
              notify("Something went wrong!", NotificationType.ERROR);
            }
         }
    }

    const handleDeletePatient = async (sessionId:string, patientId:string) => {
        try {
          await deletePatient({sessionId, patientId})
          notify("Patient deleted successfully", NotificationType.SUCCESS);
        } catch (error:any) {
          if (error?.data) {
            notify(error.data.message, NotificationType.ERROR);
          } else {
            notify("Something went wrong!", NotificationType.ERROR);
          }
        }
    }

    const handleUpdatePatient = async(sessionId:string, patient) => {
        const patientId = patient?.id;
        const updatePatientDto = {
          name: patient?.name,
          address: "",
          primaryDiagnosis : localStorage.getItem('primaryDiagnosis') || "Substance Use Disorder",
          facilityLocation: "",
          dateOfAdmission: patient?.createdAt
        }
        await updatePatient({sessionId, patientId, updatePatientDto})
    }

    return {
        handleCreate,
        addingMeet,
        handleUpdate,
        handleDelete,
        validateMeeting,
        handleAddPatient,
        addingPatient,
        handleDeletePatient,
        deletingPatient,
        handleUpdatePatient
        // patientsLoading,
        // patientsError,
        // patients
    } 
}
