import React from 'react'
import { Button } from '../Button'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'

const LogoutButton = () => {
  const navigate = useNavigate()

  const logout = () => {
    getAuth().signOut()
    navigate('/', {replace: true})
  }

  return (
    <Button onClick={logout} className="border-0 px-1 min-w-fit focus:shadow-none">
        <ArrowRightOnRectangleIcon className="w-6 h-6 hover:text-primary cursor-pointer" />
    </Button>
  )
}

export default LogoutButton