import { XMarkIcon } from "@heroicons/react/24/outline";

export default function CloseModalIcon({ closeModal }) {
  return (
    <div
      onClick={closeModal}
      className="w-5 h-5 fill-white absolute top-3 right-3 cursor-pointer"
    >
      <XMarkIcon className="fill-white" />
    </div>
  )
}
