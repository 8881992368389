import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const initAuth = () => {
  // Configure Firebase.
  const config = {
    apiKey:
      process.env.REACT_APP_FIREBASE_CLIENT_PUBLIC_API_KEY ,
    authDomain:
      process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ,
    databaseURL:
      process.env.REACT_APP_FIREBASE_DATABASE_URL
  }
  const app = firebase.initializeApp(config)
}

export default initAuth
