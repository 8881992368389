const PathConstants = {
    HOME: "/",
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT: "/resetPassword",
    MODIFY:"/avatar/modify",
    QUESTIONS: "/questions",
    POSTQUESTIONS: "/complete/questions",
    SESSION: "/session",
    ROOM: "/session/:room",
    INSTRUCTIONS: "/instructions",
    PROFILE: "/profile",
    SETTING:"/system-settings",
    ERROR:"/errors/:error",
    SUMMARY: "/summary",
    CREATESESSION: "/create-session",
    EDITSESSION: "/edit-session/:id",
    ADDPATIENTTOSESSION: "/add-patient",
    SETUPQUESTION:"/set-up-questions",
    THANKYOU: "/thank-you",
    NOTFOUND: "*"
}

export default PathConstants
