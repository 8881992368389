import React, { useRef, useEffect } from "react";

const MicrophoneTestComponent: React.FC = () => {
  const analyserNodeRef = useRef<AnalyserNode | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const requestRef = useRef<number | null>(null);
  const streamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    const initMicrophone = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          streamRef.current = stream;
      
          const audioContext = new AudioContext();
          const analyserNode = audioContext.createAnalyser();
          const source = audioContext.createMediaStreamSource(stream);
          const gainNode = audioContext.createGain(); // Create GainNode
      
          analyserNode.fftSize = 256;
          source.connect(analyserNode);
          analyserNode.connect(gainNode); // Connect to GainNode
          gainNode.connect(audioContext.destination);
      
          analyserNodeRef.current = analyserNode;
          audioContextRef.current = audioContext;
      
          animate();
        } catch (error) {
          console.error('Error accessing microphone:', error);
        }
    };

    initMicrophone();

    return () => {
      stopMicrophone();
    };
  }, []);


 const stopMicrophone = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      streamRef.current = null;
  
      // Revoke the media stream's URL
      const trackUrl = streamRef.current ? URL.createObjectURL(streamRef.current) : null;
      if (trackUrl) {
        URL.revokeObjectURL(trackUrl);
      }
    }
  
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
  
    cancelAnimationFrame(requestRef.current!);
  };

  const animate = () => {
    if (analyserNodeRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d')!;
      const dataArray = new Uint8Array(analyserNodeRef.current.frequencyBinCount);
  
      const drawWave = () => {
        analyserNodeRef.current!.getByteFrequencyData(dataArray);
  
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
  
        for (let i = 0; i < dataArray.length; i++) {
          const x = (i / dataArray.length) * canvas.width;
          const y = (dataArray[i] / 256) * canvas.height;
  
          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }
  
        ctx.strokeStyle = '#2F077F';
        ctx.lineWidth = 2;
        ctx.stroke();
  
        requestRef.current = requestAnimationFrame(drawWave);
      };
  
      drawWave();
  
      analyserNodeRef.current!.disconnect();
    }
  };

  return (
    <div className="text-center">
      <canvas ref={canvasRef} width={300} height={50} />
    </div>
  );
};

export default MicrophoneTestComponent;
