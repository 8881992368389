import React, { FC, HTMLProps } from 'react'
import { ErrorMessage, useField } from 'formik'

interface Props extends HTMLProps<HTMLInputElement> {
  name: string
}

const InputField: FC<Props> = ({ label, className, disabled, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className="flex relative w-full">
      <div className="flex-wrap mt-1 w-full">
        <input
          id={props.name}
          autoComplete="none"
          {...field}
          {...props}
          className={`
          block w-full h-[3.25rem] rounded-xl pt-6 pl-4 font-primary  focus:outline-primaryLight ${
            disabled && 'text-disabledMirror'
          } ${
            meta.touched && meta.error
              ? 'border-danger focus:ring-danger focus:border-danger'
              : 'focus:border-ringBlue'
          } ${className}
          `}
        />

        <ErrorMessage
          component="div"
          name={props.name}
          className="text-left text-xs my-1 text-danger"
        />

        {label && (
          <label
            htmlFor={props.name}
            className="absolute left-4 top-[0.6rem] text-textInput text-xs font-semibold font-primary peer-placeholder-shown:text-sm peer-focus:text-xs"
          >
            {label}
          </label>
        )}
      </div>
    </div>
  )
}

export default InputField
