import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAuth } from "firebase/auth";

interface AuthGuardProps {
  children: JSX.Element;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({
  children
}) => {

    const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      if (user) {
        navigate("/", {replace: true})
      }
    });

    return () => unsubscribe();
  }, []);

  return children;
  
};
