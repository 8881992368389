import React from 'react'
import Heading from '../../Components/Heading'
import EditSessionForm from '../../Components/EditSessionForm'

export const EditSession = () => {
  return (
    <div className="w-[40rem] mx-auto">
        <Heading level={3} className="mb-6 text-center capitalize">
            Edit session
        </Heading>
        <EditSessionForm />
    </div>
  )
}
