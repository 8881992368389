import { FC, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useField } from 'formik'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

export interface SelectOption<T, P> {
  value: T
  label: P
  image?: string
}

interface Props {
  name: string
  label: string
  options?: SelectOption<any, string>[]
  disabled?: boolean
  bg?:string
}

const SelectField: FC<Props> = ({ label, name, disabled, options = [], bg }) => {
  const [field] = useField(name)

  const onChange = (value: string) => {
    field.onChange({ target: { value, name } })
  }

  return (
    <Listbox value={field.value.value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className={`relative w-full h-[3.125rem] py-2 pl-3 pr-10 text-left pt-5 rounded-xl cursor-default focus:primaryLight sm:text-sm focus:outline-primary ${bg}`}>
            <Listbox.Label className="absolute font-semibold text-textInput top-1 text-xs peer-placeholder-shown:text-sm peer-focus:text-xs ">
              {label}
            </Listbox.Label>
            <span
              className={`capitalize block truncate text-secondary  ${
                disabled ? 'text-disabledMirror' : 'text-secondary'
              }`}
            >
              {field?.value?.label || ""}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="bg-white absolute w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
              {options.map((opt) => (
                <Listbox.Option
                  key={opt.label}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-primary' : 'text-secondary',
                      'cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-200'
                    )
                  }
                  value={opt}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center cursor-pointer">
                        {opt?.image && <img className="w-16 h-12 object-cover rounded-md mr-2" src={opt?.image} alt={opt.label} />}
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate '
                          )}
                        >
                          {opt.label}
                        </span>
                      </div>

                      {selected ?? (
                        <span
                          className={classNames(
                            active ? 'text-primary' : 'text-secondary',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
export default SelectField
