import React from 'react'
import AddPatientForm from '../../Components/AddPatientForm'
import Chip from '../../Components/Chip'
import { useMeet } from '../../Hooks/useMeet'
import Heading from '../../Components/Heading'

const AddPatientToSession = () => {
  const url = new URL(window.location.href);
  const sessionId = url.searchParams.get('id') || "";

  // const { patients, patientsLoading, patientsError, handleDeletePatient} = useMeet()
  return (
    <>  
        <Heading level={3} className="mb-10 mt-2 text-center capitalize">
            Add patients to the session
        </Heading>
        <div className='flex items-start gap-10 w-3/4 mx-auto'>  
            <div className='w-3/4 mx-auto'>
                {/* <AddPatientForm sessionId={sessionId}/> */}
            </div>
            {/* {!patientsLoading && patients && patients.length > 0 && <div className='w-1/4'>
              <Heading level={4}>
                Patients in the session
              </Heading>
              <div className="flex flex-wrap items-start gap-3 mt-3">
                  {!patientsLoading && !patientsError && patients && patients.length > 0 && patients.map((patient:any) =>
                     <Chip 
                      label={patient?.name} 
                      onDelete={() => handleDeletePatient(sessionId, patient?.id)}
                     />
                  )}
              </div>
            </div>} */}
        </div>
    </>
  )
}

export default AddPatientToSession