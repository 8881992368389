import React, { useEffect } from 'react';
import { Button } from '../Button';
import { useMeet } from '../../Hooks/useMeet';
import TimePicker from 'react-time-picker';
import DatePicker from 'react-date-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditSessionForm = ({ meet }) => {
  const { handleUpdate } = useMeet();
  const {id, name, room, startAt, description} = meet

  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 2);
  nextMonth.setDate(1);
  nextMonth.setHours(0, 0, 0, 0);

  const validationSchema = Yup.object({
    meetingName: Yup.string().required('Meeting Name is required'),
    meetingTime: Yup.string().required('Meeting Time is required'),
    meetingDate: Yup.date().required('Meeting Date is required')
  });

  const formik = useFormik({
    initialValues: {
      meetingName: '',
      meetingDescription: '',
      meetingTime: "10:00 AM",
      meetingDate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleUpdate(id, values, room);
    },
  });

  useEffect(() => {
    const fetchMeet = () => {
      const datetimeObject = new Date(startAt);
      const date = datetimeObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const time = datetimeObject.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      formik.setValues({
        meetingName: name,
        meetingDescription : description,
        meetingTime: time,
        meetingDate: date,
      });
    };
    fetchMeet();
  }, [meet]);

  return (
    <div className="max-w-2xl mx-auto rounded-md">
      <h2 className="text-2xl font-bold mb-6">Edit Meeting</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <label htmlFor="meetingName" className="block text-sm font-medium text-gray-600">
            Meeting Name
          </label>
          <input
            type="text"
            id="meetingName"
            name="meetingName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.meetingName}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md bg-white"
          />
          {formik.touched.meetingName && (
            <div className="text-red-500">{formik.errors.meetingName}</div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="meetingDescription" className="block text-sm font-medium text-gray-600">
            Meeting Description
          </label>
          <input
            type="text"
            id="meetingDescription"
            name="meetingDescription"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.meetingDescription}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md bg-white"
          />
          {formik.touched.meetingDescription && (
            <div className="text-red-500">{formik.errors.meetingDescription}</div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="meetingDate" className="block text-sm font-medium text-gray-600">
            Meeting Date
          </label>
          <DatePicker
            onChange={(value) => formik.setFieldValue('meetingDate', value)}
            value={formik.values.meetingDate}
            calendarIcon={false}
            minDate={new Date()}
            maxDate={nextMonth}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md bg-white"
          />
          {formik.touched.meetingDate && (
            <div className="text-red-500">{formik.errors.meetingDate as any}</div>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="meetingTime" className="block text-sm font-medium text-gray-600">
            Meeting Time
          </label>
          <TimePicker
            isOpen={false}
            format={"hh:mm a"}
            maxDetail={"minute"}
            onChange={(value) => formik.setFieldValue('meetingTime', value)}
            value={formik.values.meetingTime}
            clockIcon={false}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md bg-white"
          />
          {formik.touched.meetingTime && (
            <div className="text-red-500">{formik.errors.meetingTime}</div>
          )}
        </div>

        <Button
          disabled={Object.keys(formik?.errors).length > 0}
          type="submit"
          className="bg-primary text-white px-8 py-4"
        >
          Update Meeting
        </Button>
      </form>
    </div>
  );
};

export default EditSessionForm;
