import React from 'react'
import QuestionsPage from '../../Components/Questions/QuestionsPage'
import Heading from '../../Components/Heading'
import { Button } from '../../Components/Button'
import { useQuestions } from '../../Hooks/useQuestions'
import { Link } from 'react-router-dom'

export const SetUpQuestions = () => {
  const {sessionQuestions} = useQuestions()
  const noQuestions = sessionQuestions && sessionQuestions.length <= 0
  return (
    <div className='flex flex-col gap-3 w-full lg:w-3/4 mx-auto p-5 justify-center items-center'>
      <Heading level={3}>
          Manage questions for the session
      </Heading>
      <QuestionsPage/>
      <Button disabled={noQuestions} className='bg-primary text-white active:scale-95 mx-auto w-1/2'>
         <Link to="/">Continue</Link>
      </Button>
    </div>
  )
}
