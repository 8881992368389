import React, { FC, ReactNode } from 'react';
interface HeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: ReactNode;
  className?: string;
}

const Heading: FC<HeadingProps> = ({ level, children, className = '' }) => {
  const headingClasses = {
    1: 'text-2xl leading-6 sm:text-4xl sm:leading-10 font-bold', 
    2: 'text-xl leading-5 sm:leading-8 sm:text-3xl font-bold', 
    3: 'text-xl sm:text-2xl font-bold',
    4: 'text-lg sm:text-xl font-semibold', 
    5: 'text-lg sm:text-lg font-semibold', 
    6: 'text-lg sm:text-base font-semibold'
  };

  const classes = `${headingClasses[level]} ${className}`;
  const HeadingTag = `h${level}` as any;

  return <HeadingTag className={classes}>{children}</HeadingTag>;
};

export default Heading;
