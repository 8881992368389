import { useSearchParams } from "react-router-dom"
import { AnswerDto, CreateQuestionDto, UpdateQuestionsDto, useGoodThoughtsControllerAddAnswerByPatientMutation, useGoodThoughtsControllerAddQuestionToGtSessionByProviderMutation, useGoodThoughtsControllerDeleteQuestionByIdByProviderMutation, useGoodThoughtsControllerGetQuestionsForGtSessionBySessionIdQuery, useGoodThoughtsControllerUpdateQuestionByIdByProviderMutation } from "../Features/API/apiSlice.generated"
import { GetUser } from "../Features/Auth/userSlice"
import { useAppSelector } from "../Store/store"
import { useNotification } from "./useNotification"
import { NotificationType } from "../Constants/notificationType.enum"
import { POST, PRE } from '../Constants/questions';
import { USER_ROLES } from "../Constants/roles"

export const useQuestions = () => {
    const {notify} = useNotification()
    const {data:user} = useAppSelector(GetUser)
    const userRole:any = user?.gtUserData?.gtUserType
    const [searchParams] = useSearchParams()
    const sessionId = searchParams.get("id")?.toString() || ""

    const {data:sessionQuestions, isLoading: questionsLoading, isError: questionsErrors, refetch:getQuestions} = useGoodThoughtsControllerGetQuestionsForGtSessionBySessionIdQuery({
        sessionId: sessionId
    },
    {
       skip: userRole !== USER_ROLES.PROVIDER
    })

    const [addQuestion] = useGoodThoughtsControllerAddQuestionToGtSessionByProviderMutation()

    const [updateQuestion] = useGoodThoughtsControllerUpdateQuestionByIdByProviderMutation()

    const [deleteQuestion] = useGoodThoughtsControllerDeleteQuestionByIdByProviderMutation()

    const [answerQuestion] = useGoodThoughtsControllerAddAnswerByPatientMutation()

    const handleAddQuestion = async (question) => {
        const {questionText, questionType, questionTime} = question
        const createQuestionDto:CreateQuestionDto = {
            question: questionText,
            questionType: questionType,
            questionTime: questionTime,
            isDefault: false
        }
        try {
          await addQuestion({sessionId, createQuestionDto}).unwrap()
          getQuestions()
          notify("Question added successfully" , NotificationType.SUCCESS)
        } catch (error:any) {
            if(error?.data) {
                notify(error?.data?.message, NotificationType.ERROR)
            } else {
                notify("Something went wrong!", NotificationType.ERROR)
            }
        }
    }

    const handleDelete = async (id) => {
        const questionId = id
        try {
           await deleteQuestion({questionId}).unwrap()
           getQuestions()
           notify("Question deleted successfully" , NotificationType.SUCCESS)
        } catch (error:any) {
            if(error?.data) {
                notify(error?.data?.message, NotificationType.ERROR)
            } else {
                notify("Something went wrong!", NotificationType.ERROR)
            }
        }
    }

    const handleUpdateQuestion = async (id , question) => {
        const {questionText, questionType, questionTime, isDefault} = question
        const updateQuestionsDto:UpdateQuestionsDto = {
            question: questionText,
            questionType: questionType,
            questionTime: questionTime,
            isDefault: isDefault
        }
        const questionId = id
        console.log(id, question)
        try {
          await updateQuestion({questionId, updateQuestionsDto}).unwrap()
          getQuestions()
          notify("Question updated successfully" , NotificationType.SUCCESS)
        } catch (error:any) {
            if(error?.data) {
                notify(error?.data?.message, NotificationType.ERROR)
            } else {
                notify("Something went wrong!", NotificationType.ERROR)
            }
        }
    }

    const handleCopyQuestion = async (id) => {
        const questionToCopy:any = sessionQuestions && sessionQuestions.find((question:any) => question?.id === id);
        
        if (!questionToCopy) {
            notify("Question not found.", NotificationType.ERROR);
            return;
        }
        const createQuestionDto:CreateQuestionDto = {
            question: questionToCopy?.question,
            questionType: questionToCopy?.questionType,
            questionTime: questionToCopy?.questionTime === PRE ? POST : PRE,
            isDefault: false
        }
        
        try {
            await addQuestion({ sessionId, createQuestionDto: createQuestionDto }).unwrap();
            getQuestions();
            notify("Question copied successfully.", NotificationType.SUCCESS);
        } catch (error:any) {
            if (error?.data) {
                notify(error.data.message, NotificationType.ERROR);
            } else {
                notify("Something went wrong!", NotificationType.ERROR);
            }
        }
    }

    const handleAnswer = async(sessionId, answer) => {
        const answerDto:AnswerDto = {
            questionId : answer?.questionId,
            answer : answer?.text.toString()
        }
        try {
            const res = await answerQuestion({sessionId,answerDto}).unwrap()
            return res
        } catch (error:any) {
            if (error?.data) {
              notify(error.data.message, NotificationType.ERROR);
            } else {
              notify("Something went wrong!", NotificationType.ERROR);
            }
     }
    }

    return {
        searchParams,
        sessionQuestions,
        questionsLoading,
        questionsErrors,
        handleAddQuestion,
        handleUpdateQuestion,
        handleDelete,
        handleCopyQuestion,
        handleAnswer
    }

}