import React, { useEffect, useState } from "react";
import Question from "./Question";
import Heading from "../Heading";
import { Button } from "../Button";
import { useModal } from "../../Hooks/useModal";
import { ModalContent } from "../../Constants/modalContent.enum";
import {
  ASCENDING_RATING,
  BOOLEAN,
  DESCENDING_RATING,
  POST,
  PRE,
  RATING,
  TEXT,
} from "../../Constants/questions";
import { useSearchParams } from "react-router-dom";
import { useQuestions } from "../../Hooks/useQuestions";
import Paragraph from "../Paragraph";

const QuestionsPage = () => {
  const { showModalWith } = useModal();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("id")?.toString() || "";

  const { sessionQuestions, questionsLoading, handleAddQuestion } =
    useQuestions();

  const [preSessionQuestions, setPreSessionQuestions] = useState([]);

  const [postSessionQuestions, setPostSessionQuestions] = useState([]);

  const handleAddQuestionPopUp = (questionTime) => {
    showModalWith({
      name: ModalContent.AddQuestionForm,
      props: {
        questionTime,
        sessionId,
        handleAddQuestion,
      },
    });
  };

  useEffect(() => {
    if (!questionsLoading && sessionQuestions) {
      const allQuestions: any = sessionQuestions;
      setPreSessionQuestions(
        allQuestions.filter((question) => question.questionTime === PRE)
      );
      setPostSessionQuestions(
        allQuestions.filter((question) => question.questionTime === POST)
      );
    }
  }, [questionsLoading, sessionQuestions]);

  return (
    <div className="container flex flex-col md:flex-row justify-center items-start w-full mx-auto my-10 gap-16">
      <div className="flex flex-col flex-1 gap-3">
        <Heading level={3} className="text-2xl font-bold">
          Pre-session Questions
        </Heading>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            Yes/No Questions
          </Paragraph>
          {preSessionQuestions.map((question: any, index) => {
            if (question.questionType === BOOLEAN) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            Rating 0-10 Questions
          </Paragraph>
          {preSessionQuestions.map((question: any, index) => {
            if (question.questionType === RATING) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            BAM Questions: Higher scores = Higher protection from relapse
          </Paragraph>
          <Paragraph className="text-base">
           In the past 7 days, how many days did you.. 
          </Paragraph>
          {preSessionQuestions.map((question: any, index) => {
            if (question.questionType === ASCENDING_RATING) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            BAM Questions: Higher scores = Higher risk for relapse
          </Paragraph>
          <Paragraph className="text-base">
            In the past 7 days, how many days did you.. 
          </Paragraph>
          {preSessionQuestions.map((question: any, index) => {
            if (question.questionType === DESCENDING_RATING) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            Open ended Questions
          </Paragraph>
          {preSessionQuestions.map((question: any, index) => {
            if (question.questionType === TEXT) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <Button
          onClick={() => handleAddQuestionPopUp(PRE)}
          className="my-4 hover:bg-primary hover:text-white"
        >
          Add new question
        </Button>
      </div>

      <div className="flex flex-col flex-1 gap-3">
        <Heading level={3} className="text-2xl font-bold">
          Post-session Questions
        </Heading>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            Yes/No Questions
          </Paragraph>
          {postSessionQuestions.map((question: any, index) => {
            if (question.questionType === BOOLEAN) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            Rating 0-10 Questions
          </Paragraph>
          {postSessionQuestions.map((question: any, index) => {
            if (question.questionType === RATING) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            BAM Questions: Higher scores = Higher protection from relapse
          </Paragraph>
          <Paragraph className="text-base">
           In the past 7 days, how many days did you.. 
          </Paragraph>
          {postSessionQuestions.map((question: any, index) => {
            if (question.questionType === ASCENDING_RATING) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <div className="my-2">
         <Paragraph className="font-semibold 3xl:text-xl my-2">
            BAM Questions: Higher scores = Higher risk for relapse
          </Paragraph>
          <Paragraph className="text-base">
            In the past 7 days, how many days did you.. 
          </Paragraph>
          {postSessionQuestions.map((question: any, index) => {
            if (question.questionType === DESCENDING_RATING) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <div className="my-2">
          <Paragraph className="font-semibold 3xl:text-xl my-2">
            Open ended Questions
          </Paragraph>
          {postSessionQuestions.map((question: any, index) => {
            if (question.questionType === TEXT) {
              return <Question key={index} question={question} />;
            }
          })}
        </div>
        <Button
          onClick={() => handleAddQuestionPopUp(POST)}
          className="my-2 hover:bg-primary hover:text-white"
        >
          {" "}
          Add new question{" "}
        </Button>
      </div>
    </div>
  );
};

export default QuestionsPage;