import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { rtkQueryErrorLogger as rtkQueryErrorHandler } from './store-error-handler';
import { apiSlice } from '../Features/API/apiSlice.generated';
import { modalSlice } from '../Features/Modal/modalSlice';
import { notificationsSlice } from './notifications';
import { miscDataSlice } from './misc-data';

export const reducerObj = {
  [apiSlice.reducerPath]: apiSlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [miscDataSlice.name]: miscDataSlice.reducer,
};

const makeStore = () =>
  configureStore({
    reducer: reducerObj,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(rtkQueryErrorHandler)
        .concat(apiSlice.middleware),
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const store = makeStore();
