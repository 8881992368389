import { useAppSelector } from "../../Store/store";
import { GetUser } from "../../Features/Auth/userSlice";

interface Prop {
    url?: string
    update?: (url:string) => void
}
export default function AvatarModelCard({url, update}:Prop) {
  const {data:user} = useAppSelector(GetUser)

  const avatarUrl = (!user?.avatarUrl || user?.avatarUrl.includes("themirror://avatar/astronaut-male")) ? "https://models.readyplayer.me/65bfd6cc99ee375dcfb45d1f.glb" : user?.avatarUrl  
  const avatarPngUrl = avatarUrl.replace("https://models.readyplayer.me/", " https://api.readyplayer.me/v1/avatars/").replace(".glb", ".png")
  const pngUrl = url?.replace("https://models.readyplayer.me/", " https://api.readyplayer.me/v1/avatars/").replace(".glb", ".png")

  return (
      <div onClick={() => url !== undefined && update && update(url!)} className="relative m-2 w-[10rem] h-[10rem] lg:h-[15rem] lg:w-[15rem] cursor-pointer p-2">
        {avatarUrl && 
            <img className={avatarUrl === url ? "border border-primary rounded-md shadow-custom" : "hover:scale-105 transition-all"} src={pngUrl ? pngUrl : avatarPngUrl} alt={pngUrl ? pngUrl : avatarPngUrl} />
        }
      </div>
  );
}