import { apiSlice } from '../API/apiSlice.generated';

apiSlice.enhanceEndpoints<'VidSdk'>({
  endpoints: {
    goodThoughtsControllerGetVideoSdkToken: {
      providesTags: (result, error, arg) => [{ type: 'VidSdk' }],
    },
  },
});

export const getVidSdkAuth = apiSlice.endpoints.goodThoughtsControllerGetVideoSdkToken.select();
