import React from "react";
import Heading from "../../Components/Heading";

const Instructions = () => {
  return (
    <div>
      <Heading level={1} className="mt-3 mb-8">
        {" "}
        Instructions for Ready Player Me{" "}
      </Heading>
      <ul className="flex flex-col gap-5 list-disc px-5">
        <li>
          Visit{" "}
          <a className="text-primary" href="https://readyplayer.me/avatar" target="_blank" rel="noopener">
          https://readyplayer.me/avatar
          </a>
        </li>
        <li>Choose your body type</li>
        <li>
          Continue by either using a photo on file or continue without a photo
          for stock avatars to customize
        </li>
        <li>Pick an Avatar</li>
        <li>Select clothing for your avatar</li>
        <li>
          Once clothing is selected and "Next" is clicked, a pop-up will appear
          with the link to copy for use in the GT app
        </li>
        <li>
          The file will be in a .glb file format, such as these four examples:
          <ul>
            <li>
              <a
                className="text-primary"
                href="https://models.readyplayer.me/6567d9e4e5520094996896f0.glb"
                rel="noopener"
                target="_blank"
              >
                https://models.readyplayer.me/6567d9e4e5520094996896f0.glb
              </a>
            </li>
            <li>
              <a
                className="text-primary"
                href="https://models.readyplayer.me/6567dbd7fd16ab329c94c934.glb"
                rel="noopener"
                target="_blank"
              >
                https://models.readyplayer.me/6567dbd7fd16ab329c94c934.glb
              </a>
            </li>
            <li>
              <a
                className="text-primary"
                href="https://models.readyplayer.me/6567dc1ffd16ab329c94ca1f.glb"
                rel="noopener"
                target="_blank"
              >
                https://models.readyplayer.me/6567dc1ffd16ab329c94ca1f.glb
              </a>
            </li>
            <li>
              <a
                className="text-primary"
                href="https://models.readyplayer.me/6567dc7dfd16ab329c94cb29.glb"
                rel="noopener"
                target="_blank"
              >
                https://models.readyplayer.me/6567dc7dfd16ab329c94cb29.glb
              </a>
            </li>
          </ul>
        </li>
        <li>
          In the Good Thought Dev app, before clicking on "Discover" to go to
          the therapy room, select "Avatar" from the top bar
        </li>
        <li>Click on the Ready Player Me "Import Avatar" button</li>
        <li>Paste the file link into the black box and click "Connect"</li>
        <li>Wait for the "Loading" sign as the file is imported</li>
        <li>Click "Continue with Avatar"</li>
        <li>Choose "Discover" from the top bar</li>
        <li>Search for "Therapy Room Demo" and double click on it</li>
        <li>Click "Build" to enter the Therapy Room Demo</li>
      </ul>
    </div>
  );
};

export default Instructions;
