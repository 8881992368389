import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './store';

interface SetTokenPayload {
  token: string;
}

export interface IMiscData {
  userToken?: string;
}

const initialState: IMiscData = {
  userToken: undefined,
};

export const miscDataSlice = createSlice({
  name: 'miscData',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<SetTokenPayload>): void {
      state.userToken = action.payload.token;
    },
    removeToken(state): void {
      state.userToken = undefined;
    },
  },
});

export const { setToken, removeToken } = miscDataSlice.actions;

export const selectUserToken = (state: AppState) => state.miscData;
