import { getAuth } from 'firebase/auth'
import { useEffect } from 'react'
import { miscDataSlice } from '../../Store/misc-data'
import { useAppDispatch } from '../../Store/store'
import { apiSlice } from '../API/apiSlice.generated'

/** Loads authorized user and their collections into state */
export function useInitUser() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    getAuth().onAuthStateChanged((user: any) => {
      if (user) {
        dispatch(
          apiSlice.endpoints.userControllerGetCurrentUser.initiate()
        )
        dispatch(
          apiSlice.endpoints.goodThoughtsControllerGetVideoSdkToken.initiate()
        )
        dispatch(
          apiSlice.endpoints.goodThoughtsControllerFindSessions.initiate({ id: "" })
        )
        dispatch(
          apiSlice.endpoints.goodThoughtsControllerFindSessionsWhereUserCreator.initiate()
        )
        dispatch(
          apiSlice.endpoints.spaceControllerFindSpaceTemplates.initiate()
        )
        // save the JWT for WS communication
        if (user.accessToken) {
          dispatch(
            miscDataSlice.actions.setToken({ token: user.accessToken })
          )
        }

      } else {
        dispatch(apiSlice.util.resetApiState())
      }
    })
  }, [dispatch])
}
