import { showNotification, unShowNotification } from '../Store/notifications'
import { NotificationType } from '../Constants/notificationType.enum'
import { useAppDispatch } from '../Store/store'

export const useNotification = () => {
  const dispatch = useAppDispatch()

  const notify = (message: string, type: NotificationType) => {
    if (!message) {
      message = 'Something went wrong'
    }

    dispatch(showNotification({ message, type }))

    setTimeout(() => dispatch(unShowNotification()), 5000)
  }

  return { notify }
}
