export const PRE = "PRE"
export const POST = "POST"

export const RATING = "RATING"
export const BOOLEAN = "BOOLEAN"
export const TEXT = "TEXT"
export const ASCENDING_RATING = "ASCENDING_RATING"
export const DESCENDING_RATING = "DESCENDING_RATING"

export const addictionList = [
    { value: "severe-opioid-use-disorder", label: "Severe Opioid Use Disorder" },
    { value: "severe-alcohol-use-disorder", label: "Severe Alcohol Use Disorder" },
    { value: "severe-cocaine-use-disorder", label: "Severe Cocaine Use Disorder" },
    { value: "moderate-opioid-use-disorder", label: "Moderate Opioid Use Disorder" },
    { value: "moderate-alcohol-use-disorder", label: "Moderate Alcohol Use Disorder" },
    { value: "moderate-cocaine-use-disorder", label: "Moderate Cocaine Use Disorder" }
];