import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./Pages/NotFound";
import routes from "./Routes";
import Layout from "./Layout";
import { NotificationContainer } from "./Features/Notification";
import {useAppDispatch} from "./Store/store";
import initAuth from "./Features/Firebase";
import { enhanceEndpoints } from "./Features/API/enhanceEndpoint";
import './App.css';
import { setupListeners } from "@reduxjs/toolkit/query";
import { useInitUser } from "./Features/Auth/initUser";

function App() {
    const dispatch = useAppDispatch();
    // initialize firebase
    initAuth();
    // rtk query enhancements
    enhanceEndpoints();

    useInitUser()

    setupListeners(dispatch)

    const router = createBrowserRouter([
      {
        element: <Layout />,
        errorElement: <NotFound />,
        children: routes
      }
    ]);

    return  <NotificationContainer>
                 <RouterProvider router={router} />
            </NotificationContainer>
            
}

export default App;
