import { apiSlice } from '../API/apiSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiSlice.enhanceEndpoints<'SpaceTemplates'>({
  endpoints: {
    spaceControllerFindSpaceTemplates: {
      providesTags: (result, error, arg) => [{ type: 'SpaceTemplates' }]
    },
  }
})

export const selectSpaceTemplates =
  apiSlice.endpoints.spaceControllerFindSpaceTemplates.select()
