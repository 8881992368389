import React, { ReactNode } from 'react'
import cn from "classnames"

type Props = {
  children?: ReactNode
  className?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: 'button' | 'submit' | 'reset' | undefined
  isLoading?: boolean
  icon?: boolean
}

export const Button: React.FC<Props> = ({
  disabled = false,
  children,
  onClick,
  type = 'button' as 'button',
  className = '',
  isLoading = false,
  icon = false
}) => {
  return (
    <button
      type={type}
      className={`
      ${cn(
        'relative transition-all flex justify-center max-h-[3.125rem] items-center whitespace-nowrap p-3 rounded-lg font-semibold border border-primary',
        `${icon ? 'min-w-fit' : 'min-w-[6rem]'} active:scale-95`,
        'mobile:text-xs',
        className
      )}
      ${
        disabled
          ? 'opacity-70 cursor-not-allowed'
          : 'focus:shadow-[0_2px_20px_0px_rgba(57,121,255,0.4)]'
      }
    `}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
