import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAuth } from "firebase/auth";

interface ProtectedRouteProps {
  redirectPath: string;
  children: JSX.Element;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirectPath,
  children
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      if (!user) {
        navigate(redirectPath , {replace:true});
      } 
    });

    return () => unsubscribe();
  }, [navigate, redirectPath]);

  return children;
  
};
